import React, { useState, useEffect } from "react";
import Card from "../components/Card";

const CardReader = () => {
  const [applink, setData] = useState([]);
  const [filterTag, setFilterTag] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("data.json");
        const jsonData = await response.json();

        const isInterne = process.env.REACT_APP_DATA_TYPE === "interne";

        const filteredApp = jsonData.app.filter(item => {
          if (isInterne) return true; // On garde tout
          return item.public === "oui"; // On exclut les internes si pas en mode interne
        });
        setData(filteredApp);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données JSON:",
          error
        );
      }
    };

    const savedFilterTag = localStorage.getItem("filterTag");
    if (savedFilterTag) {
      setFilterTag(savedFilterTag);
    }

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterTag(value);
    localStorage.setItem("filterTag", value);
  };

  const filteredData = filterTag
    ? applink.filter((item) => item.tag.includes(filterTag))
    : applink;

  return (
    <div className="flex-1 w-full max-w-screen-xl mx-auto p-4">
  <div className="mb-6 flex items-center justify-center space-x-4">
    <label
      htmlFor="filter"
      className="text-md font-semibold text-gray-700 dark:text-gray-300"
    >
      Type de contenu:
    </label>
    <select
      id="filter"
      className="w-40 px-4 py-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 transition-all"
      value={filterTag}
      onChange={handleFilterChange}
    >
      <option value="">Tout</option>
      <option value="dashboard">Dashboard</option>
      <option value="api">API</option>
      <option value="application">Application</option>
      <option value="website">Site web</option>
    </select>
  </div>


      <div className="p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5">
        {filteredData.map((item, index) => (
          <Card
            image={item.image}
            title={item.title}
            description={item.description}
            link={item.link}
            tags={item.tag}
            doc={item.doc}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default CardReader;
